import React from 'react';
import './style.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getSharerUrl, socialNetworks, socialShareIconsTestId } from './constants';

export interface Props {
  title: string;
  url: string;
}

export const SocialShareIcons: React.FC<Props> = ({ title, url }) => {
  return (
    <div className="d-flex" data-testid={socialShareIconsTestId}>
      {socialNetworks.map((socialNetwork, idx) => (
        <a
          key={idx}
          href={getSharerUrl(title, socialNetwork.type, url)}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`share on ${socialNetwork.type}`}
          className={`${idx < socialNetworks.length - 1 ? 'mr-4' : ''} ${
            socialNetwork.transform ? 'overflow-hidden' : 'bg-gray-300'
          } btn p-0 social-icon-button rounded-circle border-0 text-center`}
        >
          <FontAwesomeIcon
            className={` ${socialNetwork.transform ? 'fz-18 transform-1-20 text-gray-300' : 'fz-11 text-dark'}`}
            icon={socialNetwork.icon}
          />
        </a>
      ))}
    </div>
  );
};

export default SocialShareIcons;
