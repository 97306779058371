import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';
import { GatsbyImage } from 'gatsby-plugin-image';
import './style.scss';
import { blogPostPreHeaderTestId } from './constants';

export interface Props {
  authorName?: string;
  createdAt: string;
  authorPicture?: IGatsbyImageData;
  authorPictureAlt?: string;
}

export const BlogPostPreHeader: React.FC<Props> = ({
  children,
  createdAt,
  authorName,
  authorPicture,
  authorPictureAlt = '',
}) => {
  return (
    <section className="pt-md-8 py-6 py-md-6 container-fluid blogPreHeader" data-testid={blogPostPreHeaderTestId}>
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-12 text-center mb-5 mb-md-0">
            {authorPicture && (
              <GatsbyImage
                alt={authorPictureAlt}
                className={authorName?.toLowerCase() === 'stackhawk' ? 'stackhawkPicture' : 'authorPicture'}
                image={authorPicture}
              />
            )}
            <p className="text-gray-300 mb-0 fz-12">
              {authorName && <span className="d-inline-block mr-2">{authorName}</span>}
              {authorName && <span className="d-inline-block mr-2">|</span>}
              <span className="d-inline-block">{createdAt}</span>
            </p>
          </div>
          <div className="col-12 col-md-9 fz-18 d-flex flex-column justify-content-center text-center text-md-left blogPreHeader_copy px-0 ">
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogPostPreHeader;
