import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { getSubscriptionInitialState, IFormSubscription } from '../../core/subscription';
import { EmailSubscribeFormTestId, emailSubscribePlaceholder } from './constants';

export interface Props {
  onSubmit: (formData: IFormSubscription) => void;
}

export const EmailSubscribeForm: React.FC<Props> = ({ onSubmit }) => {
  const [formState, setFormState] = useState<IFormSubscription>({
    ...getSubscriptionInitialState(),
    subscribe: ['company_info'],
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  return (
    <Form
      className="w-100"
      data-testid={EmailSubscribeFormTestId}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(formState);
        setFormState(getSubscriptionInitialState());
      }}
    >
      <Form.Group className="mb-md-0 mb-4" controlId="emailSubscribeForm">
        <Form.Control
          onChange={handleInputChange}
          value={formState.email}
          className="fz-14"
          type="email"
          name="email"
          aria-label={emailSubscribePlaceholder}
          placeholder={emailSubscribePlaceholder}
          required
        />
      </Form.Group>
      <Button className="fz-14" variant="primary" type="submit" disabled={formState.email === ''}>
        Subscribe
      </Button>
    </Form>
  );
};

export default EmailSubscribeForm;
