import { IGatsbyImageData } from 'gatsby-plugin-image';
import { RelatedPost } from './index';

export const defaultThumbnailImage: IGatsbyImageData = {
  images: {
    sources: [
      {
        srcSet:
          '//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=176&h=63&q=80&fm=webp 176w,\n//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=352&h=126&q=80&fm=webp 352w,\n//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=704&h=252&q=80&fm=webp 704w',
        sizes: '(min-width: 704px) 704px, 100vw',
        type: 'image/webp',
      },
    ],
    fallback: {
      src: '//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=704&h=252&q=80&fm=png',
      srcSet:
        '//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=176&h=63&q=80&fm=png 176w,\n//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=352&h=126&q=80&fm=png 352w,\n//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=704&h=252&q=80&fm=png 704w',
      sizes: '(min-width: 704px) 704px, 100vw',
    },
  },
  layout: 'constrained',
  width: 704,
  height: 252,
  placeholder: {
    fallback:
      'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAMAAAA/FZ0KAAAAS1BMVEUyPUs0OEY3PEsuMT8rO0gyNUMvMkAyNkQzN0UwM0EsN0QxNUMzN0YxNEI3PEo1Okg0OUc2Okg1OUcvM0EtNEIsOEUyNUQ5PkwxQU7UfsRHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAB3RJTUUH5QUKEiEkgj7JqQAAAE5JREFUCB0FwYcBgkAAALE/gyLYsLv/pCZjVO2KCUx7RlWVCeAwj6o6LsDKqUbV+QLgcq7ruNUJwHJsq/toPgCYq/ujxnPl9YaPtm/96g+NZwKFF1VphgAAAABJRU5ErkJggg==',
  },
};
export const relatedPostTestId = 'related-posts';

export const relatedPosts: RelatedPost[] = [
  {
    thumbnailImage: {
      images: {
        sources: [
          {
            srcSet:
              '//images.ctfassets.net/nx13ojx82pll/ECwdkSLg3xl0iVR0eEYXn/9885f17471a6f4c4cf63261d28e0fd84/Django-CORS-Guide-Thumbnail_2x.png?w=176&h=63&q=80&fm=webp 176w,\n//images.ctfassets.net/nx13ojx82pll/ECwdkSLg3xl0iVR0eEYXn/9885f17471a6f4c4cf63261d28e0fd84/Django-CORS-Guide-Thumbnail_2x.png?w=352&h=126&q=80&fm=webp 352w,\n//images.ctfassets.net/nx13ojx82pll/ECwdkSLg3xl0iVR0eEYXn/9885f17471a6f4c4cf63261d28e0fd84/Django-CORS-Guide-Thumbnail_2x.png?w=704&h=252&q=80&fm=webp 704w',
            sizes: '(min-width: 704px) 704px, 100vw',
            type: 'image/webp',
          },
        ],
        fallback: {
          src: '//images.ctfassets.net/nx13ojx82pll/ECwdkSLg3xl0iVR0eEYXn/9885f17471a6f4c4cf63261d28e0fd84/Django-CORS-Guide-Thumbnail_2x.png?w=704&h=252&q=80&fm=png',
          srcSet:
            '//images.ctfassets.net/nx13ojx82pll/ECwdkSLg3xl0iVR0eEYXn/9885f17471a6f4c4cf63261d28e0fd84/Django-CORS-Guide-Thumbnail_2x.png?w=176&h=63&q=80&fm=png 176w,\n//images.ctfassets.net/nx13ojx82pll/ECwdkSLg3xl0iVR0eEYXn/9885f17471a6f4c4cf63261d28e0fd84/Django-CORS-Guide-Thumbnail_2x.png?w=352&h=126&q=80&fm=png 352w,\n//images.ctfassets.net/nx13ojx82pll/ECwdkSLg3xl0iVR0eEYXn/9885f17471a6f4c4cf63261d28e0fd84/Django-CORS-Guide-Thumbnail_2x.png?w=704&h=252&q=80&fm=png 704w',
          sizes: '(min-width: 704px) 704px, 100vw',
        },
      },
      layout: 'constrained',
      width: 704,
      height: 252,
      placeholder: {
        fallback:
          'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAMAAAA/FZ0KAAAAvVBMVEUyPUs0OEY3PEszN0YuMT8rO0gyNUMvMkAyNkQvRFEmXmcmXWclVl8lT1kiWGEfY2siWWIjVF4hXWYjV2AxNUM+QU9BRVI1PkwzVF8vR1MmTFcqPUo0TFgwQk8qPkosR1M3UVwpQU06PUs1OUc8QE43PEo1OkhRVGFpa3hub3teYG1cXmtqa3hbXWppa3djZHFrbXlYWmdxdIBzdYFeYW5bXms2OkkvM0EsN0QtNEIsOEUyNUQ0OUc5PkwxQU5fpBu8AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAB3RJTUUH5QUKESIMnuCMyQAAAF9JREFUCB0FwStuAmEAgMH5lk2o45E6bPEYQMD9L0HCDRCVhGz/ziQ0pjSg/hQw1QCsmuGrqqp+bV9mvquqqjb1+pmPj33VmKqqsqv1WADmN8flabqcFucLXH1ud48D/1yDEv9WUP+MAAAAAElFTkSuQmCC',
      },
    },
    altThumbnailImage: '',
    textTop: 'Django CORS Guide:',
    textMiddle: 'What It Is and How to Enable It',

    slug: 'django-cors-guide',
  },
  {
    thumbnailImage: {
      images: {
        sources: [
          {
            srcSet:
              '//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=176&h=63&q=80&fm=webp 176w,\n//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=352&h=126&q=80&fm=webp 352w,\n//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=704&h=252&q=80&fm=webp 704w',
            sizes: '(min-width: 704px) 704px, 100vw',
            type: 'image/webp',
          },
        ],
        fallback: {
          src: '//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=704&h=252&q=80&fm=png',
          srcSet:
            '//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=176&h=63&q=80&fm=png 176w,\n//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=352&h=126&q=80&fm=png 352w,\n//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=704&h=252&q=80&fm=png 704w',
          sizes: '(min-width: 704px) 704px, 100vw',
        },
      },
      layout: 'constrained',
      width: 704,
      height: 252,
      placeholder: {
        fallback:
          'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAMAAAA/FZ0KAAAAS1BMVEUyPUs0OEY3PEsuMT8rO0gyNUMvMkAyNkQzN0UwM0EsN0QxNUMzN0YxNEI3PEo1Okg0OUc2Okg1OUcvM0EtNEIsOEUyNUQ5PkwxQU7UfsRHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAB3RJTUUH5QUKEiEkgj7JqQAAAE5JREFUCB0FwYcBgkAAALE/gyLYsLv/pCZjVO2KCUx7RlWVCeAwj6o6LsDKqUbV+QLgcq7ruNUJwHJsq/toPgCYq/ujxnPl9YaPtm/96g+NZwKFF1VphgAAAABJRU5ErkJggg==',
      },
    },
    altThumbnailImage: '',
    textTop: 'Django CORS Guide:',
    textMiddle: 'What It Is and How to Enable It',

    slug: 'django-cors-guide-what-it-is-and-how-to-enable-it-v2',
  },
  {
    thumbnailImage: {
      images: {
        sources: [
          {
            srcSet:
              '//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=176&h=63&q=80&fm=webp 176w,\n//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=352&h=126&q=80&fm=webp 352w,\n//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=704&h=252&q=80&fm=webp 704w',
            sizes: '(min-width: 704px) 704px, 100vw',
            type: 'image/webp',
          },
        ],
        fallback: {
          src: '//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=704&h=252&q=80&fm=png',
          srcSet:
            '//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=176&h=63&q=80&fm=png 176w,\n//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=352&h=126&q=80&fm=png 352w,\n//images.ctfassets.net/nx13ojx82pll/e1n3pK2AzWA4pLbNNRmRJ/fc6b9d81e3648b645cf8282338a12012/Burp_Enterprise_vs._StackHawk_-_Thumbnail_2x.png?w=704&h=252&q=80&fm=png 704w',
          sizes: '(min-width: 704px) 704px, 100vw',
        },
      },
      layout: 'constrained',
      width: 704,
      height: 252,
      placeholder: {
        fallback:
          'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAMAAAA/FZ0KAAAAS1BMVEUyPUs0OEY3PEsuMT8rO0gyNUMvMkAyNkQzN0UwM0EsN0QxNUMzN0YxNEI3PEo1Okg0OUc2Okg1OUcvM0EtNEIsOEUyNUQ5PkwxQU7UfsRHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAB3RJTUUH5QUKEiEkgj7JqQAAAE5JREFUCB0FwYcBgkAAALE/gyLYsLv/pCZjVO2KCUx7RlWVCeAwj6o6LsDKqUbV+QLgcq7ruNUJwHJsq/toPgCYq/ujxnPl9YaPtm/96g+NZwKFF1VphgAAAABJRU5ErkJggg==',
      },
    },
    altThumbnailImage: '',
    textTop: 'Django CORS Guide:',
    textMiddle: 'What It Is and How to Enable It',

    slug: 'django-cors-guide-what-it-is-and-how-to-enable-it-v3',
  },
];
