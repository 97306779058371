import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { faReddit } from '@fortawesome/free-brands-svg-icons/faReddit';
import { faYCombinator } from '@fortawesome/free-brands-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';

export const socialShareIconsTestId = 'social-share';

export interface ISocialNetworks {
  type: 'fb' | 'twitter' | 'linkedin' | 'ycombinator' | 'reddit';
  transform?: boolean;
  icon: IconProp;
}

export const socialNetworks: ISocialNetworks[] = [
  {
    type: 'twitter',
    icon: faXTwitter,
  },
  {
    type: 'fb',
    icon: faFacebookF,
  },
  {
    type: 'reddit',
    icon: faReddit,
    transform: true,
  },
  {
    type: 'ycombinator',
    transform: true,
    icon: faYCombinator,
  },
  {
    type: 'linkedin',
    icon: faLinkedin,
  },
];

export const mockedTitle = 'Dynamic Application SecurityTesting (DAST): Overviewand Tooling Guide';

export const getSharerUrl = (title: string, type: ISocialNetworks['type'], url = ''): string => {
  const encodedTitle = encodeURI(title);
  const currentUrl = encodeURI(url);
  switch (type) {
    case 'twitter':
      url = `https://twitter.com/share?text=${encodedTitle}&url=${currentUrl}`;
      break;
    case 'fb':
      url = `https://www.facebook.com/sharer.php?u=${currentUrl}`;
      break;
    case 'reddit':
      url = `https://www.reddit.com/submit?url=${currentUrl}&title=${encodedTitle}`;
      break;
    case 'ycombinator':
      url = `https://news.ycombinator.com/submitlink?u=${currentUrl}&t=${encodedTitle}`;
      break;
    case 'linkedin':
      url = `https://www.linkedin.com/shareArticle/?mini=true&url=${currentUrl}`;
      break;
  }
  return url;
};
