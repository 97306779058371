import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { defaultThumbnailImage, relatedPostTestId } from './constants';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';
import { Link } from 'gatsby';

export interface RelatedPost {
  thumbnailImage: IGatsbyImageData;
  altThumbnailImage?: string;
  textTop?: string;
  textMiddle?: string;
  textBottom?: string;
  slug?: string;
}

export interface Props {
  relatedPosts: RelatedPost[];
  title?: string;
}

export const RelatedPosts: React.FC<Props> = ({ relatedPosts, title = 'Read More' }) => {
  return (
    <section className="bg-asphalt py-5" data-testid={relatedPostTestId}>
      <div className="container">
        <h4 className="mb-5 font-weight-bold">{title}</h4>
        <div className="row">
          {relatedPosts?.map(
            (post, idx) =>
              post && (
                <Link className="col-12 col-md-4 mb-5 mb-md-0" key={idx} to={`/blog/${post.slug}/`}>
                  <GatsbyImage
                    alt={post.altThumbnailImage || ''}
                    className="mb-2"
                    image={post.thumbnailImage || defaultThumbnailImage}
                  />

                  <p className="fz-18">
                    {post?.textTop && <span className="mr-1 text-primary">{post?.textTop}</span>}
                    {post?.textMiddle && <span className="mr-1 text-white">{post?.textMiddle}</span>}
                    {post?.textBottom && <span className="text-white">{post?.textBottom}</span>}
                  </p>
                </Link>
              )
          )}
        </div>
      </div>
    </section>
  );
};

export default RelatedPosts;
