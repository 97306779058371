import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

export const blogPostPreHeaderTestId = 'blog-pre-header';
export const authorPicture: IGatsbyImageData = {
  images: {
    sources: [
      {
        srcSet:
          '//images.ctfassets.net/nx13ojx82pll/5J0KTj8w4Ql0BoUA06v0al/a12bbb0d428cdd85b90c9a17efd24dca/stackhawk-author.png?w=28&h=28&q=80&fm=webp 28w,\n//images.ctfassets.net/nx13ojx82pll/5J0KTj8w4Ql0BoUA06v0al/a12bbb0d428cdd85b90c9a17efd24dca/stackhawk-author.png?w=57&h=57&q=80&fm=webp 57w,\n//images.ctfassets.net/nx13ojx82pll/5J0KTj8w4Ql0BoUA06v0al/a12bbb0d428cdd85b90c9a17efd24dca/stackhawk-author.png?w=113&h=113&q=80&fm=webp 113w,\n//images.ctfassets.net/nx13ojx82pll/5J0KTj8w4Ql0BoUA06v0al/a12bbb0d428cdd85b90c9a17efd24dca/stackhawk-author.png?w=226&h=226&q=80&fm=webp 226w',
        sizes: '(min-width: 113px) 113px, 100vw',
        type: 'image/webp',
      },
    ],
    fallback: {
      src: '//images.ctfassets.net/nx13ojx82pll/5J0KTj8w4Ql0BoUA06v0al/a12bbb0d428cdd85b90c9a17efd24dca/stackhawk-author.png?w=113&h=113&q=80&fm=png',
      srcSet:
        '//images.ctfassets.net/nx13ojx82pll/5J0KTj8w4Ql0BoUA06v0al/a12bbb0d428cdd85b90c9a17efd24dca/stackhawk-author.png?w=28&h=28&q=80&fm=png 28w,\n//images.ctfassets.net/nx13ojx82pll/5J0KTj8w4Ql0BoUA06v0al/a12bbb0d428cdd85b90c9a17efd24dca/stackhawk-author.png?w=57&h=57&q=80&fm=png 57w,\n//images.ctfassets.net/nx13ojx82pll/5J0KTj8w4Ql0BoUA06v0al/a12bbb0d428cdd85b90c9a17efd24dca/stackhawk-author.png?w=113&h=113&q=80&fm=png 113w,\n//images.ctfassets.net/nx13ojx82pll/5J0KTj8w4Ql0BoUA06v0al/a12bbb0d428cdd85b90c9a17efd24dca/stackhawk-author.png?w=226&h=226&q=80&fm=png 226w',
      sizes: '(min-width: 113px) 113px, 100vw',
    },
  },
  layout: 'constrained',
  width: 113,
  height: 113,
  placeholder: {
    fallback:
      'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAACylBMVEUAAAAAzsBXZHJXZXJXaHBXZXEAysVWZHJWZXNXZXIAy8UAy8VXZHJXZHNWZHJWZHJXZHNWZHIAysUAy8UAysUAysVWZHJXZHNXZHJXZHNXZHJXZXNXZHJXZXNWZXMAysUAysUAy8UAy8ZWZXJXZXJWZHJXZHJXZHJWZHJXZXJXZXJXZHJXZXJXZXNWZHJXZXNXZXIAy8UAy8UAysUAysYMvLpWZXJXZXJWZHJWZHJXZHJWZHJWZHJXZXJWZHJXZXJXZXNWZHILvbsAysYAysUAysUAysUAy8UNu7lXZHJXZHJWZHJWZXJXZXJXZHILvboAy8YAysUAysVWZHIMvboAy8YAy8YFxcFQa3hUZ3UExsIAy8UKv7xXZHJWZHJRa3gAysUAysUAy8YAysUAysUAysUAy8UAysUAysUAy8YAysUAysZJdH9XZXNWZXJDfIYAy8UAysUAy8UAy8UAy8UAy8YAy8UAy8UAy8YAysUAy8UAy8UAysUyj5VXZHIKvrsAysUAysUAysUAy8YAy8YAy8YAysUAysUQt7ZWZHJXZHNXZXJWZXIExsIAysUAysUAysYAysYAysUAysYAy8YAy8YAy8UAysUHwr9WZXNWZHJXZHNWZXNWZHIAy8YAy8YAy8ZXZXJXZXJWZXIAysUAysVWZXNXZXNWZHJXZXJXZXMAy8UAy8YAy8ZWZHJXZXJXZXJWZHJXZXNXZXNWZXJWZHJJdYAAysUAy8YAy8UAy8VEeoRWZHJXZHNXZHNXZHJXZXJXZXJXZXJXZXIExcEAy8YAy8YCyMRXZHJWZHJXZXJXZXJXZHJXZHJXZHJXZHJXZXJVZnRHd4ELvrsAysVJdYBRa3hXZXJXZHJWZHJXZHJXZHJXZXMAysUAysUAysVXZHNXZHJWZHJWZHJPbnogpacMvbpSandXZHI0jZMYra5WZHIAysYAy8Z5ZCVoAAAA7HRSTlMAAAAAAAAPRlwBYA8lnmpTpjcLZCifDoWKPoI6d5UbljGnZQJjoD15ViwvUXswlHkIW7El4ldLZXAfVywpVyBsaDlN5CsjZutdI09DQFEhU+toKSaHdul5CQdvdotQQlygntKxF0FcFKrWnKc0bEJweIPP3OxtyPZd6t7QhkxWNZnprbny7KSaQi1sVmk7aY6h57im6qSQa0FsLlZwTEv7V0xvcC87Qno1UAYF8/kET3k8jHwhUjR//P6HMVR6ZkWnSmphWvT3X1RuN6NdAQRsnCc/UFI+F46BDBOMf11+YWKbISMocoFBJBkrA7abKRoAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQflBQQVKynj8DwoAAAA/0lEQVQYGV3BTSsEcQDH8d93/WeGnVlpihyQdeGyJ+/AyUM5OOxZWSRZr0BKyp1EnsrBQUop2oOD9g3sC3Ah626i1V4s859dh93PR65avO4eNbmkqSkWYH0oFmLSfP9qgJgg4E3KgLJ0qhiFdJJRSKLUUNcMCaXUm3hoSD/3QYDv+zLysQpny5ysQh8gVKTNy1h0YBSNwmt2nUNYO4YcGaHiMO2uykZRDpakC0qzi9Il9blySpKnmDt/5ChRldF0zTjX5L0nNnTL/mZdQnfc5Pm3tbswsTeFzCPWeYHTFRKTSKpgfX0OYY1LyHomtrMNjCiGmqpYg0qgFucd+tX0Bx3wNLOzGG0nAAAAAElFTkSuQmCC',
  },
};
export const authorPictureAlt = 'stackhawk';
export const authorName = 'Stackhawk';
export const createdAt = 'July 24, 2022';
export const preHeaderCopy = `With our official HawkScan Action for GitHub Actions, continuous API security testing in the pipeline is easier than ever.`;
