import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layouts/default';
import SEO from '../components/seo';
import { renderRichText } from 'core/renderRichText';
import PageHeader from '../components/PageHeader';
import TableOfContents from '../components/TableOfContents';
import SocialShareIcons from '../components/SocialShareIcons';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';
import { BlogPostPreHeader } from '../components/BlogPostPreHeader';
import RelatedPosts, { RelatedPost } from 'components/RelatedPosts';
import { defaultThumbnailImage } from 'components/RelatedPosts/constants';
import { getAltText } from 'core/utils';
import './style.scss';
import NewsletterModal from 'components/_POC/Modal/NewsletterModal';
import { Helmet } from 'react-helmet';

const BlogPostTemplate: React.FC = ({ data, location }: any) => {
  const post: GatsbyTypes.ContentfulBlogPost = data.contentfulBlogPost;
  const seo = post.seo;
  const postTitle = post?.seo?.title || '';
  const body = post?.body ? post?.body : null;
  const author = post?.blogAuthor;
  const slug = post?.slug;
  const blogPageHeader = post?.blogPageHeader;
  // this holds the default suggested post if not defined in contentful
  const defaultSuggestedBlogPosts: GatsbyTypes.ContentfulBlogPost[] = data.defaultSuggestedPosts.edges.map(
    (edge: any) => edge.node
  );
  const blogSuggestedPosts = post?.relatedPosts?.length ? post?.relatedPosts : defaultSuggestedBlogPosts;
  const relatedPosts: RelatedPost[] =
    blogSuggestedPosts?.map((blog) => {
      return {
        thumbnailImage: blog?.thumbnailImage?.gatsbyImageData || defaultThumbnailImage,
        altThumbnailImage: getAltText(blog?.thumbnailImage),
        textTop: blog?.blogPageHeader?.textTop,
        textMiddle: blog?.blogPageHeader?.textMiddle,
        textBottom: blog?.blogPageHeader?.textBottom,
        slug: blog?.slug,
      };
    }) || [];
  return (
    <Layout>
      <SEO data={{ ...seo, ogType: 'article' }} />
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "${post?.seo?.title || ''}",
            "name": "${post?.seo?.title || ''}",
            "description": "${post?.seo?.description || ''}",
            "image": ["${blogPageHeader?.backgroundImage ? blogPageHeader?.backgroundImage?.gatsbyImageData : ''}"],
            "datePublished": "${post?.date || ''}",
            "dateModified": "${post?.date || ''}",
            "author": {
                "@type": "Person",
                "name": "${author?.authorName || ''}",
                "url": "${author?.authorPicture?.gatsbyImageData?.images?.fallback?.src}"
              },
            "publisher": {
              "@type": "Organization",
              "@id": "https://www.stackhawk.com",
              "@name": "StackHawk"
            },
            "url": "https://www.stackhawk.com/blog/${slug}/",
            "isPartOf": {
              "@type": "Blog",
              "@id": "https://www.stackhawk.com/blog/",
              "name": "StackHawk Blog",
              "publisher": {
                "@type": "Organization",
                "@id": "https://www.stackhawk.com",
                "name": "StackHawk"
              }
            }
          }
          `}
        </script>
      </Helmet>
      <PageHeader
        textTop={blogPageHeader?.textTop as string}
        textMiddle={blogPageHeader?.textMiddle}
        textBottom={blogPageHeader?.textBottom}
        backgroundImage={blogPageHeader?.backgroundImage ? blogPageHeader?.backgroundImage?.gatsbyImageData : undefined}
        subHeader={
          author && (
            <BlogPostPreHeader
              authorName={author?.authorName || ''}
              authorPicture={author?.authorPicture?.gatsbyImageData as unknown as IGatsbyImageData}
              createdAt={post?.date || ''}
              authorPictureAlt={author?.authorPicture?.title || ''}
            >
              {post?.preHeaderCopy && renderRichText(post?.preHeaderCopy)}
            </BlogPostPreHeader>
          )
        }
      />

      {body && (
        <article className="container py-5" key={'blog-post-body'}>
          <div className="row">
            <div className="col-lg-3 col-12 d-none d-lg-block">
              <div className="sticky-topbar">
                <div className="mb-4">
                  <TableOfContents body={body} />
                </div>
                <div className="pl-3">
                  <SocialShareIcons title={postTitle} url={location.href} />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-9 blog-post-article" style={{ lineHeight: '169.52%' }}>
              {renderRichText(body)}

              <hr className="my-4" />
              <div className="fz-12 text-gray-300">
                {author?.authorName}&nbsp;&nbsp;|&nbsp;&nbsp;{post?.date}
              </div>
            </div>
          </div>
        </article>
      )}

      <RelatedPosts relatedPosts={relatedPosts} />
      <NewsletterModal />
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    defaultSuggestedPosts: allContentfulBlogPost(
      sort: { order: ASC, fields: slug }
      filter: {
        slug: {
          in: [
            "using-stackhawk-in-gitlab-know-before-you-go-live"
            "add-appsec-to-circle-ci-pipeline"
            "application-security-is-broken"
          ]
        }
      }
    ) {
      edges {
        node {
          ...ContentfulBlogPost
        }
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      seo {
        ...ContentfulSeo
      }
      ...ContentfulBlogPost
    }
  }
`;
