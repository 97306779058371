import React, { useEffect, useState } from 'react';
import { useLocation } from '@gatsbyjs/reach-router';
import EmailSubscribeInput from 'components/EmailSubscribeInput';
import { DefaultModal } from '../../../foundations/Modals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as modalStyles from './style.module.scss';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';

const TOP_BLOG_POST_URLS = [
  '/blog/fixing-no-access-control-allow-origin-header-present/',
  '/blog/angular-cors-guide-examples-and-how-to-enable-it/',
  '/blog/angular-cors-guide-examples-and-how-to-enable-it/',
  '/blog/laravel-cors/',
  '/blog/django-cors-guide/',
  '/blog/react-xss-guide-examples-and-prevention/',
  '/blog/vue-cors-guide-what-it-is-and-how-to-enable-it/',
  '/blog/react-content-security-policy-guide-what-it-is-and-how-to-enable-it/',
  '/blog/golang-cors-guide-what-it-is-and-how-to-enable-it/',
];

export const NewsletterModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [beenSubmitted, setBeenSubmitted] = useState(false);
  const [scrollAmount, setScrollAmount] = useState(100);
  const location = useLocation();

  const localStorageExists = typeof window !== 'undefined';

  const setLocalStorageShowModal = (input: boolean): void => {
    localStorageExists && localStorage.setItem('subscribeFormSubmitted', JSON.stringify(input));
  };

  const setLocalStorageShowModalTrue = () => setLocalStorageShowModal(true);

  const getLocalStorageShowModal = localStorageExists && JSON.parse(localStorage.getItem('subscribeFormSubmitted'));

  const shouldShowModal =
    location.pathname &&
    scrollAmount &&
    TOP_BLOG_POST_URLS.includes(location.pathname) &&
    scrollAmount > 2000 &&
    getLocalStorageShowModal !== true;

  useEffect(() => {
    if (shouldShowModal) {
      setShowModal(true);
    }
  }, [location, scrollAmount, getLocalStorageShowModal]);

  useEffect(() => {
    const onScroll = (e: Event) => {
      setScrollAmount(e.target.documentElement.scrollTop);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollAmount]);

  const handleSubmit = (hasBeenSubmitted: boolean) => {
    setBeenSubmitted(hasBeenSubmitted);
    setLocalStorageShowModalTrue();
  };

  const handleClose = () => {
    setShowModal(false);
    setLocalStorageShowModalTrue();
  };

  return (
    <>
      <DefaultModal
        className="newsletter-modal"
        size="lg"
        onHide={() => {
          if (window && window.history) {
            setLocalStorageShowModalTrue();
            window.history.replaceState({}, document.title, window.location.href.split('#')[0]);
          }
        }}
        showButtons={false}
        show={showModal}
        showModal={setShowModal}
        content={
          !beenSubmitted ? (
            <div className={`p-4 mt-4 position-relative rounded text-center ${modalStyles.subscribeModal}`}>
              <h3 className="text-center mb-4">
                Want the latest <br />
                <span>Hawkness</span> in your inbox?
              </h3>
              <p>
                No spam, just the latest Hawknews, delivered every month. Give your inbox something to KaaKaww about!
              </p>
              <EmailSubscribeInput handleClose={handleClose} handleSubmit={handleSubmit} />
              <div className={modalStyles.times}>
                <a onClick={() => handleClose()} aria-label="Close">
                  <FontAwesomeIcon color="#8999AF" icon={faXmark} />
                </a>
              </div>
            </div>
          ) : (
            <EmailSubscribeSuccess handleClose={handleClose} />
          )
        }
      />
    </>
  );
};

export default NewsletterModal;

interface EmailSubscribeSuccessProps {
  handleClose: () => void;
}

export const EmailSubscribeSuccess: React.FC<EmailSubscribeSuccessProps> = ({ handleClose }) => {
  const [showModal, setShowModal] = useState(true);

  return (
    <DefaultModal
      size="lg"
      header={''}
      showButtons={false}
      show={showModal}
      showModal={setShowModal}
      content={
        <div
          style={{ maxWidth: '488px' }}
          className={`p-4 mt-4 position-relative rounded text-center ${modalStyles.subscribeModal}`}
        >
          <h3 className="mb-4">
            Now That's something <br />
            to <span>KaaKaww!</span> about!
          </h3>
          <p>
            You're now subscribed to our newsletter. Get ready for the latest Hawknews delivered right to your inbox.
          </p>
          <div className={modalStyles.times}>
            <a onClick={() => handleClose()} aria-label="Close">
              <FontAwesomeIcon color="#8999AF" icon={faXmark} />
            </a>
          </div>
        </div>
      }
    />
  );
};
