import { DefaultModal } from 'foundations/Modals';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { emailSubscribeInputTestId } from './constants';
import './style.scss';
import EmailSubscribeForm from '../EmailSubscribeForm';
import { IFormSubscription, submitSubscriptionForm } from '../../core/subscription';
import EmailSubscribeFormSuccess from '../EmailSubscribeFormSuccess';
import { API_SUBSCRIBE_ENDPOINT } from 'core/links';
import { EmailSubscribeSuccess } from 'components/_POC/Modal/NewsletterModal';

interface EmailSubscribeInputProps {
  handleClose?: () => void;
  handleSubmit?: (hasBeenSubmitted: boolean) => void;
}

export const EmailSubscribeInput: React.FC<EmailSubscribeInputProps> = ({ handleClose, handleSubmit }) => {
  const [emailModal, setEmailModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const onSubmit = (args: IFormSubscription) => {
    submitSubscriptionForm(API_SUBSCRIBE_ENDPOINT)(args);
    setFormSubmitted(true);
    handleSubmit?.(true);
  };

  return (
    <div className="email-subscribe-form" data-testid={emailSubscribeInputTestId}>
      <div className="email-input">
        <EmailSubscribeForm onSubmit={onSubmit} />
      </div>
      <div className="email-modal text-center">
        <Button className="fz-14" variant="primary" type="submit" onClick={() => setEmailModal}>
          Subscribe
        </Button>
        <DefaultModal
          size="lg"
          header="Subscribe!"
          showButtons={false}
          show={emailModal}
          showModal={setEmailModal}
          content={<EmailSubscribeForm onSubmit={onSubmit} />}
        />
        {!!handleClose && formSubmitted ? (
          <EmailSubscribeSuccess handleClose={handleClose} />
        ) : (
          <DefaultModal
            size="lg"
            header={''}
            showButtons={false}
            show={formSubmitted}
            showModal={setFormSubmitted}
            content={<EmailSubscribeFormSuccess />}
          />
        )}
      </div>
    </div>
  );
};

export default EmailSubscribeInput;
